import React, { Component } from 'react';
import OT from '@opentok/client';
import './VideoChat.css';
// import 'tracking'
// import '../face'

// const Twilio = window.Twilio;
// let Video = Twilio.Video;
// let connect = Video.connect;

// let userName ="test";
// let roomName ="test";
// let previewTracks;
// let myVideo;
// let theirVideo;
// console.log('Video: ',Video);
  
// let screenTrack;
// let activeRoom;

class VideoChat extends Component {
    
//   // Attach the Tracks to the DOM.
//   attachTracks = (tracks, container) =>{
//     tracks.forEach((track)=> {
//       console.log('track:', track);
//       container.appendChild(track.attach());
//     });
//   }
  
//   // Attach the Participant's Tracks to the DOM.
//   attachParticipantTracks = (participant, container) => {
//     var tracks = this.getTracks(participant);
//     this.attachTracks(tracks, container);
//   }
  
//   // Detach the Tracks from the DOM.
//   detachTracks = (tracks) => {
//     tracks.forEach((track) => {
//       track.detach().forEach((detachedElement) => {
//         detachedElement.remove();
//       });
//     });
//   }
  
//   // Detach the Participant's Tracks from the DOM.
//   detachParticipantTracks = (participant) => {
//     var tracks = this.getTracks(participant);
//     this.detachTracks(tracks);
//   }
  
//   // Get the Participant's Tracks.
//   getTracks = (participant) => {
//     return Array.from(participant.tracks.values()).filter((publication) => {
//       return publication.track;
//     }).map((publication) => {
//       return publication.track;
//     });
//   }
  
//   // Successfully connected!
//   roomJoined = (room) => {
//     window.room = activeRoom = room;
//     console.log("Joined as '" + userName + "'");
  
//     // Attach the Tracks of the Room's Participants.
//     room.participants.forEach((participant) => {
//       console.log("Already in Room: '" + participant.identity + "'");
//       // var previewContainer = document.getElementById('remote-video');
//       var previewContainer = document.getElementById('theirVideo');
//       this.attachParticipantTracks(participant, previewContainer);
//     });
  
//     // When a Participant joins the Room, log the event.
//     room.on('participantConnected', (participant) => {
//       console.log("Joining: '" + participant.identity + "'");
//     });
  
//     // When a Participant adds a Track, attach it to the DOM.
//     room.on('trackSubscribed', (track, participant) => {
//       console.log(participant.identity + " added track: " + track.kind);
//       var previewContainer = document.getElementById('theirVideo');
//       this.attachTracks([track], previewContainer);
//     });
  
//     // When a Participant removes a Track, detach it from the DOM.
//     room.on('trackUnsubscribed', (track, participant) => {
//       console.log(participant.identity + " removed track: " + track.kind);
//       this.detachTracks([track]);
//     });
  
//     // When a Participant leaves the Room, detach its Tracks.
//     room.on('participantDisconnected', (participant) => {
//       console.log("Participant '" + participant.identity + "' left the room");
//       this.detachParticipantTracks(participant);
//     });
  
//     // Once the LocalParticipant leaves the room, detach the Tracks
//     // of all Participants, including that of the LocalParticipant.
//     room.on('disconnected', () => {
//       console.log('Left');
//       if (previewTracks) {
//         previewTracks.forEach((track) => {
//           track.stop();
//         });
//         previewTracks = null;
//       }
//       this.detachParticipantTracks(room.localParticipant);
//       room.participants.forEach(this.detachParticipantTracks);
//       activeRoom = null;
//       // document.getElementById('button-join').style.display = 'inline';
//       // document.getElementById('button-leave').style.display = 'none';
//     });
//   }
  
//   // Leave Room.
//   leaveRoomIfJoined = () => {
//     if (activeRoom) {
//       activeRoom.disconnect();
//     }
//   }

//   startVideoChat = () => {
//     console.log('connect');
//     userName = Math.floor(Math.random() * Math.floor(1000));
//     roomName = this.props.gameId;
//     fetch(`https://copper-havanese-2128.twil.io/video-token?identity=${userName}&room=${roomName}`,{
//         method: "GET",
//         mode: "cors", // no-cors, cors, *same-origin
//         cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//         credentials: "same-origin", // include, *same-origin, omit
//         headers: {
//             // "Content-Type": "application/json; charset=utf-8",
//             // "Content-Type": "application/x-www-form-urlencoded",
//         }
//     })
//         .then((response) => {
//             console.log('response: ',response);

//         return response.json();
//     })
//     .then((responseJson)=> {
//         console.log('token: ',responseJson.token);
//         connect(responseJson.token, {name: roomName, video: true, audio: true})
//         .then(async roomResponse => {
//             console.log(`Connected to Room: ${roomResponse.name}`);
//             this.roomJoined(roomResponse);  
//         });
//     })
//     .catch(error => {
//         console.log('error: ',error);
//     });
// }

  componentDidMount=()=>{
    const session = OT.initSession(this.props.apiKey,this.props.sessionId);
    console.log('session: ',session);

    // Subscribe to a newly created stream
    session.on('streamCreated', (event) => {
      session.subscribe(event.stream, 'subscriber', {
        insertMode: 'append',
        width: '100%',
        height: '100%'
      },(error)=>{
        if (error) {
          alert(error.message);
        }
      })
    })

    // Create a publisher
    const publisher = OT.initPublisher('publisher',{
      insertMode: 'append',
      width: '100%',
      height: '100%'

    }, (error) => {
      if (error) {
        alert(error.message);
      }
    });

    //Connet to the session
    session.connect(this.props.token, (error) => {
      // If the connection is successful, initialize a publisher and publish to the session
      if (error) {
        alert(error.message);
      } else {
        session.publish(publisher, (error) => {
          if (error) {
            alert(error.message);
          }
        })
      }
    })


    // const tracking = window.tracking;
    // //var tracker = new tracking.ObjectTracker('face');
    // console.log(this.props.game);
    // console.log('here are the props: ',this.props);
    // roomName = this.props.gameId;
    // userName = Math.floor(Math.random() * Math.floor(1000));
    // this.myVideo = document.getElementById('myVideo');
    // this.theirVideo = document.getElementById('theirVideo');
    // this.startVideoChat();

    // //Show your video
    // navigator.mediaDevices.getUserMedia({audio: true, video: true})
    //   .then(stream => this.myVideo.srcObject = stream);
    // var canvas = document.getElementById('myCanvas');
    // var context = canvas.getContext('2d');
    // var tracker = new tracking.ObjectTracker('face');
    // console.log('tracker: ',tracker)
    // tracker.setInitialScale(4);
    // tracker.setStepSize(2);
    // tracker.setEdgesDensity(0.1);
    // tracking.track('#myVideo', tracker, { camera: true });
    // tracker.on('track', (event) => {
    //   context.clearRect(0, 0, canvas.width, canvas.height);
    //   event.data.forEach((rect) => {
    //     context.strokeStyle = '#a64ceb';
    //     context.strokeRect(rect.x, rect.y, rect.width, rect.height);
    //     context.font = '11px Helvetica';
    //     context.fillStyle = "#fff";
    //     context.fillText('x: ' + rect.x + 'px', rect.x + rect.width + 5, rect.y + 11);
    //     context.fillText('y: ' + rect.y + 'px', rect.x + rect.width + 5, rect.y + 22);
    //   });
    // });
  }

  // sendMessage = (senderId, data) => {
  //   console.log(`senderId: ${senderId} data: ${data}`);
  // }

  render() {
    return (
      <section className="VideoChat">
        {/* <video id="myVideo" autoPlay muted></video> */}
        {/* <canvas id="myCanvas" width="320" height="240"></canvas> */}
        {/* <div id="theirVideo"></div> */}
        <div id="publisher"></div>
        <div id="subscriber"></div>
      </section>
    )
  }
}

export default VideoChat;
