import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import base from '../rebase';
import NexmoClient from 'nexmo-client';

import './Game.css';
import StartGame from './StartGame';
import JoinGame from './JoinGame';
import PlayGame from './PlayGame';
import RejoinGame from './RejoinGame';
import VideoChat from './VideoChat';

// let signedIn = false;
class Game extends Component {
  constructor (props){
    super(props);
    this.state = {
      game:{
        xPlayer:'',
        oPlayer:'',
        started: false,
        creatorName:'',
        moves:{'0':'','1':'','2':'','3':'','4':'','5':'','6':'','7':'','8':''},
        currentMove:'',
        winner:'',
        winningPositions:[]
      },
      playerMark:'',
      playerName:'',
      step: 0,
      displayError: false,
      opponent: '',
      gameCreator: false,
      signedIn: false,
      myMoves:[],
      showStart:false,
      showJoin: false,
      showRejoin: false,
      showError: false,
      showShare: false,
      showWait: false,
      showPlay: false,
      showVideoChat: false,
      videoAPIKey:'',
      videoSessionId:'',
      videoToken:'',
      errorText:'',
    }
    // let nexmoApp;
    let app;
    let conversation;
    let gameId;
    let shortURL;
    let phoneNumber;
  }

  componentDidMount = async () =>{
    console.log('nexmoApp Game.js: ', this.nexmoApp)
    var myHeaders = new Headers();

    var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    console.log(this.props.match.params.gameId);
    if (this.props.match.params.gameId === 'create' && this.state.game.xPlayer==="" && this.state.game.oPlayer===""){
      this.setState({gameCreator: true, showStart:true});
    }
    if (this.props.match.params.gameId !== 'create'){
      console.log('game already created');
      // look to see who joined
      try {
        const response = await fetch('https://special-quilt-sign.glitch.me/getPlayersCount',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            conversationId: `CON-${this.props.match.params.gameId}`
          })
        });
        const results = await response.json();
        if (response.status !== 200 ){
          throw new Error(results.error);
        }
        console.log('results: ', results);
        if (results.count === 1){
          if (results.mark === 'X'){
            this.setState({showJoin:true, game:{...this.state.game, creatorName:results.creator, xPlayer:results.creator}});
          } else {
            this.setState({showJoin:true, game:{...this.state.game, creatorName:results.creator, oPlayer:results.creator}});
          }
        } else if (results.count === 2){
          this.setState({showRejoin:true});
        }
      }catch(error){
        console.log('getPlayerCount error: ', error.message);
        this.setState({showError:true, errorText:error.message});
      }

    }
    // base.syncState(`game/${this.props.match.params.gameId}`, {
    //   context: this,
    //   state: 'game',
    //   asArray: false,
    //   then(results){
    //     fetch(`https://api-ssl.bitly.com/v4/shorten?access_token=f98a5451d060cb4b77ed81efcd3d430992f25a7e&longUrl=https%3A%2F%2Fticreacttoev2.firebaseapp.com%2Fgame%2F${this.props.match.params.gameId}`,myInit)
    //     .then(response => {
    //       return response.json()
    //     }).then(data => {
    //       // console.log(data.data.url)
    //       let pageLoadPlusOne = this.state.game.pageLoad + 1;
    //       base.update(`game/${this.props.match.params.gameId}`,{
    //         data: {url:data.data.url, pageLoad: pageLoadPlusOne}
    //       })
    //       if (this.state.game.xPlayer==="" && this.state.game.oPlayer===""){
    //         this.setState({gameCreator: true})
    //       }
    //     }).catch(function (error) {
    //       console.log(error)
    //     });
    //   }
    // })
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    // console.log('componentDidUpdate prevProps: ',prevProps, this.props);
    // console.log('componentDidUpdate prevState: ',prevState, this.state);
    if (prevProps.location.pathname !== this.props.location.pathname){
      this.setState({showShare:false,showWait:true});
    }
  }
  

  setAppAndCoversation = (appFromComponent, conversationFromComponent) => {
    console.log('app: ', appFromComponent);
    console.log('conversation: ', conversationFromComponent);
  }

  setPlayer = (playerName, playerMark, startGame) => {
    console.log(`playerName: ${playerName} playerMark: ${playerMark} startGame: ${startGame}`);
    this.setState({playerMark});
    if (this.state.game.creatorName === ''){
      // base.update(`game/${this.props.match.params.gameId}`,{
      //   data: {creatorName:playerName}
      // })
    }
    if (playerMark==="X"){
      // base.update(`game/${this.props.match.params.gameId}`,{
      //   data: {xPlayer:playerName}
      // })
    } else {
      // base.update(`game/${this.props.match.params.gameId}`,{
      //   data: {oPlayer:playerName}
      // })
    }
    this.setState({signedIn:true});
    // base.update(`game/${this.props.match.params.gameId}`,{
    //   data: {started:startGame}
    // })
  }

  verifyPlayer = (verified, playerMark, myMoves) => {
    // console.log(`verified: ${verified} playerMark: ${playerMark}`)
    this.setState({signedIn:verified, playerMark, myMoves});
  }


  setApp = async(playerName) => {
    console.log('setApp playerName: ',playerName);
    try {
      const response = await fetch('https://special-quilt-sign.glitch.me/join',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: playerName.toLowerCase(),
          display_name: playerName
        })
      });
      const results = await response.json();
      if (response.status !== 200 ){
        throw new Error(results.error);
      }
      console.log('results: ', results);
      this.app = await new NexmoClient({debug: false}).login(results.jwt);
      console.log('this.app: ', this.app);
      this.setState({playerName:playerName});
      return;

    }catch(err){
      console.log('playerNameEntered error: ', err.message);
    }


  }


  setConversation = async(conversationId) => {
    console.log('setConversation: ',conversationId);
    this.conversation = await this.app.getConversation(`CON-${this.props.match.params.gameId}`);
    return;
  }


  setGameState = (playerName, playerMark) => {
    console.log(`setGameState playerName: ${playerName} playerMark: ${playerMark}`);
    if (playerMark==="X"){
      this.setState({game:{...this.state.game, xPlayer:playerName}});
      // base.update(`game/${this.props.match.params.gameId}`,{
      //   data: {xPlayer:playerName}
      // })
    } else {
      this.setState({game:{...this.state.game, oPlayer:playerName}});
      // base.update(`game/${this.props.match.params.gameId}`,{
      //   data: {oPlayer:playerName}
      // })
    }  
  }

  getConversationEvents = async() => {
    console.log('getConversationEvents: ',this.state.playerName);
    let events_page = await this.conversation.getEvents({order:'asc',page_size:100})
    events_page.items.forEach((value, key) => {
      console.log('value, key: ', value, key);
      switch (value.type){
        case 'player_joined':
          console.log('player_joined');
          if (this.state.playerName === value.body.name){
            console.log('player name matches!!!')
            this.setState({playerMark:value.body.mark})
          }
          this.setGameState(value.body.name, value.body.mark);
          break;
        case 'first_move':
          console.log('first_move');
          this.setState({game:{...this.state.game, currentMove:value.body.move}});
          break;  
        case 'game_started':
          console.log('game_started');
          this.setState({
            showStart:false,
            showJoin: false,
            showRejoin: false,
            showError: false,
            showShare: false,
            showWait: false,
            showPlay: true
          })
    
          break;
        case 'move_made':
          console.log('move_made',this.state.playerMark,value.body.mark);
          if (this.state.playerMark === value.body.mark){
            // this.setState({[...this.state.myMoves, [value.body.space]]})
            console.log('MOVE MADE MATCH!!!!');
            this.setState(state => {
              const myMoves = state.myMoves.concat(value.body.space);
              return {
                myMoves
              };
            });
          }
          this.setState({game:{...this.state.game, currentMove:value.body.mark === 'X' ? 'O' : 'X', moves:{...this.state.game.moves, [value.body.space]:value.body.mark} }})
          break;
        case 'got_a_winner':
          console.log('got_a_winner',value.body.winningPositions);
          this.setState({game:{...this.state.game, winner:value.body.winner, winningPositions:[...this.state.game.winningPositions].concat(value.body.winningPositions)}});
          break;

        case 'start_video_chat':
          console.log('start_video_chat');
          this.setState({videoAPIKey:value.body.videoAPIKey, videoSessionId:value.body.videoSessionId, videoToken:value.body.videoToken, showVideoChat:true});
          break;  
            
        default:
          console.log('default: ',value.type);
      }
    });

    this.conversation.on('first_move', (sender, message) => {
      console.log('*** first move: ', sender, message);
      this.setState({game:{...this.state.game, currentMove:message.body.move}})
    });

    this.conversation.on('player_joined', (sender, message) => {
      console.log('*** player joined: ', sender, message);
      this.setGameState(message.body.name, message.body.mark);
    });

    this.conversation.on('game_started', (sender, message) => {
      console.log('*** game started: ', sender, message);
      this.setState({
        showStart:false,
        showJoin: false,
        showRejoin: false,
        showError: false,
        showShare: false,
        showWait: false,
        showPlay: true
      })
    });

    this.conversation.on('start_video_chat', (sender, message) => {
      console.log('start_video_chat');
      this.setState({videoAPIKey:message.body.videoAPIKey, videoSessionId:message.body.videoSessionId, videoToken:message.body.videoToken, showVideoChat:true});
    })


    this.conversation.on('player_move', (sender, message) => {
      console.log('*** player move: ', sender, message);
    });

    this.conversation.on('move_made', (sender, message) => {
      console.log('*** move made: ', sender, message);
      // this.setState({game:{...this.state.game, currentMove:message.body.mark === 'X' ? 'O' : 'X'}});
      // if (this.state.playerMark === message.body.mark){
      //   console.log('MOVE MADE MATCH!!!!');
      //   // this.setState({[...this.state.myMoves, [value.body.space]]})
      //   this.setState(state => {
      //     const myMoves = state.myMoves.concat(message.body.space);
      //     return {
      //       myMoves
      //     };
      //   });
      // }

      this.setState({game:{...this.state.game, currentMove:message.body.mark === 'X' ? 'O' : 'X', moves:{...this.state.game.moves, [message.body.space]:message.body.mark} }})

    });

    this.conversation.on('got_a_winner', (sender, message) => {
      console.log('got_a_winner',message.body.winningPositions);
      this.setState({game:{...this.state.game, winner:message.body.winner , winningPositions:[...this.state.game.winningPositions].concat(message.body.winningPositions)}});

      // this.setState(state => {
      //   const winningPositions = state.game.winningPositions.concat(message.body.winningPositions);
      //   return { game:winningPositions };
      // });

    });

    this.conversation.on('new_game', async (sender, message) => {
      console.log('new_game',message.body.conversationId);

      // join the new conversation
      this.conversation = await this.app.getConversation(message.body.conversationId);
      this.member = await this.conversation.join();

      // clear out some state variables
      this.setState({game:{...this.state.game, winner:message.body.winner , winningPositions:[...this.state.game.winningPositions].concat(message.body.winningPositions)}});

      this.setState({
        game:{
          started: false,
          creatorName:'',
          moves:{'0':'','1':'','2':'','3':'','4':'','5':'','6':'','7':'','8':''},
          currentMove:'',
          winner:'',
          winningPositions:[]
        },
        step: 0,
        displayError: false,
        opponent: '',
        gameCreator: false,
        signedIn: false,
        myMoves:[],
        showStart:false,
        showJoin: false,
        showRejoin: false,
        showError: false,
        showShare: false,
        showWait: false,
        showPlay: false,
        errorText:'',
      });

      // start video chat
      this.getVideoCreds();

      // send custom events player_joined, first_move and game_started
      this.conversation.sendCustomEvent({ type: 'player_joined', body: {memberId: this.member.id, name: this.state.playerName, mark:this.state.playerMark }})
      .then((custom_event) => {
        console.log('player_joined custom event: ',custom_event);
        let firstMove = ['X','O'][Math.floor(Math.random() * Math.floor(2))];
        console.log('random first move: ', firstMove);
        this.conversation.sendCustomEvent({ type: 'first_move', body: {move: firstMove }})
        .then((custom_event) => {
          console.log('first_move custom event: ',custom_event);
          this.conversation.sendCustomEvent({ type: 'game_started', body: {started: true}})
          .then((custom_event) => {
            console.log('game_started custom event: ',custom_event);
            // this.setState({playerMark});
            this.getConversationEvents();
      
          }).catch(function (error) {
              console.log('setPlayerToGame error: ',error)
          });  
  
    
        }).catch(function (error) {
            console.log('setPlayerToGame error: ',error)
        });
    
  
      }).catch(function (error) {
          console.log('setPlayerToGame error: ',error)
      });
  
      // change URL in address bar
      const state = {}
      const title = ''
      const url = this.conversation.id.replace('CON-','')

      window.history.pushState(state, title, url)

      // this.setState(state => {
      //   const winningPositions = state.game.winningPositions.concat(message.body.winningPositions);
      //   return { game:winningPositions };
      // });

    })



  }


  shortenURL = () => {
    this.gameId = this.conversation.id.replace('CON-','');
    console.log('gameId: ', this.gameId);
    // fetch(`https://api-ssl.bitly.com/v3/shorten?access_token=f98a5451d060cb4b77ed81efcd3d430992f25a7e&longUrl=https%3A%2F%2Fticreacttoe.com%2Fgame%2F${this.gameId}`)
    fetch(`https://api-ssl.bitly.com/v4/shorten`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer f98a5451d060cb4b77ed81efcd3d430992f25a7e'
      },
      body: JSON.stringify({
        long_url: `https://ticreacttoe.com/game/${this.gameId}`
      })
    })
    .then(response => {
      return response.json()
    }).then(data => {
      console.log('bitly link: ',data);
      this.shortURL = data.link;
      //2b9df9864434fa4317f2606e4f732ccadd56785b
      // let pageLoadPlusOne = this.state.game.pageLoad + 1;
      // base.update(`game/${this.props.match.params.gameId}`,{
      //   data: {url:data.data.url, pageLoad: pageLoadPlusOne}
      // })
      // if (this.state.game.xPlayer==="" && this.state.game.oPlayer===""){
      //   this.setState({gameCreator: true})
      // }
    }).catch(function (error) {
      console.log(error)
    });

  }

  getVideoCreds = async() => {
    try {
      const response = await fetch('https://special-quilt-sign.glitch.me/opentokcreds');
      const results = await response.json();
      if (response.status !== 200 ){
        throw new Error(results.error);
      }
      console.log('getVideoCreds results: ',results);
      this.conversation.sendCustomEvent({ type: 'start_video_chat', body: {videoAPIKey:results.apiKey, videoSessionId:results.sessionId, videoToken:results.token}})
      .then((custom_event) => {
        console.log('start_video_chat custom event: ',custom_event);
  
      }).catch(function (error) {
          console.log('getVideoCreds error: ',error)
      });

    }catch(error){
      console.log('getPlayerCount error: ', error.message);
      this.setState({showError:true, errorText:error.message});
    }

  }


  startGame = async(playerName, playerMark, startGame) => {
    console.log(`startGame playerName: ${playerName} playerMark: ${playerMark} startGame: ${startGame}`);
    await this.setApp(playerName);
    this.conversation = await this.app.newConversation({display_name:`conversation-${Date.now()}`});
    this.member = await this.conversation.join();
    console.log('this.conversation.id: ', this.conversation.id);
    // set up bitly url shortener
    this.shortenURL();
    console.log('this.member.id: ',this.member.id);
    this.conversation.sendCustomEvent({ type: 'player_joined', body: {memberId: this.member.id, name: playerName, mark:playerMark }})
    .then((custom_event) => {
      console.log('player_joined custom event: ',custom_event);
      let firstMove = ['X','O'][Math.floor(Math.random() * Math.floor(2))];
      console.log('random first move: ', firstMove);
      this.conversation.sendCustomEvent({ type: 'first_move', body: {move: firstMove }})
      .then((custom_event) => {
        console.log('first_move custom event: ',custom_event);
        this.getVideoCreds();
  
      }).catch(function (error) {
          console.log('setPlayerToGame error: ',error)
      });
  

    }).catch(function (error) {
        console.log('setPlayerToGame error: ',error)
    });

    this.setState({playerMark, showShare:true, showStart:false});

    this.getConversationEvents();

  }


  joinGame = async(playerName, playerMark, startGame) => {
    this.setState({showError:false});
    console.log(`joinGame playerName: ${playerName} playerMark: ${playerMark} startGame: ${startGame}`);
    await this.setApp(playerName);
    await this.setConversation(`CON-${this.props.match.params.gameId}`);
    try {
      this.member = await this.conversation.join();
      console.log('this.conversation: ', this.conversation);
      console.log('this.member.id: ',this.member.id);
      this.conversation.sendCustomEvent({ type: 'player_joined', body: {memberId: this.member.id, name: playerName, mark:playerMark }})
      .then((custom_event) => {
        console.log('player_joined custom event: ',custom_event);
        this.conversation.sendCustomEvent({ type: 'game_started', body: {started: true}})
        .then((custom_event) => {
          console.log('game_started custom event: ',custom_event);
          this.setState({playerMark});
          this.getConversationEvents();
    
        }).catch(function (error) {
            console.log('setPlayerToGame error: ',error)
        });  
  
      }).catch(function (error) {
          console.log('setPlayerToGame error: ',error)
      });
  
    } catch(error) {
      this.setState({showError:true,errorText:'Name already taken.'})

    }


    // this.setState({playerMark});
    // if (this.state.game.creatorName === ''){
    //   // base.update(`game/${this.props.match.params.gameId}`,{
    //   //   data: {creatorName:playerName}
    //   // })
    // }
    // if (playerMark==="X"){
    //   // base.update(`game/${this.props.match.params.gameId}`,{
    //   //   data: {xPlayer:playerName}
    //   // })
    // } else {
    //   // base.update(`game/${this.props.match.params.gameId}`,{
    //   //   data: {oPlayer:playerName}
    //   // })
    // }
    // this.setState({signedIn:true});
    // // base.update(`game/${this.props.match.params.gameId}`,{
    // //   data: {started:startGame}
    // // })



  }


  rejoinGame = async(playerName) => {
    console.log('rejoinGame playerName: ',playerName);
    await this.setApp(playerName);
    await this.setConversation(`CON-${this.props.match.params.gameId}`);
    console.log('rejoinGame conversation: ',this.conversation);

    this.getConversationEvents();

  }

  recordMove = (move) => {
    console.log('recordMove: ', move, this.state.game.currentMove);
    this.conversation.sendCustomEvent({ type: 'move_made', body: {space: move, mark:this.state.game.currentMove}})
    .then((custom_event) => {
      console.log('game_started custom event: ',custom_event);

    }).catch(function (error) {
        console.log('recordMove error: ',error)
    });  

    // console.log(`move: ${move}`);
    // base.update(`game/${this.props.match.params.gameId}/moves`,{
    //   data: {
    //     [move]:this.state.playerMark
    //   }
    // }).then(
    // base.update(`game/${this.props.match.params.gameId}`,{
    //   data: {
    //     currentMove:this.state.game.currentMove === 'X' ? 'O' : 'X'
    //   }
    // }))
    // this.setState({game[currentMove]: this.state.game.currentMove === 'X' ? 'O' : 'X'})
  }

  recordWin = (winningPositions) => {
    console.log('recordWin: ', winningPositions);
    this.conversation.sendCustomEvent({ type: 'got_a_winner', body: {winningPositions: winningPositions, winner: this.state.playerName}})
    .then((custom_event) => {
      console.log('game_started custom event: ',custom_event);

    }).catch(function (error) {
        console.log('recordWin error: ',error)
    });  


    // console.log('winningPositions: ',winningPositions);
    // base.update(`game/${this.props.match.params.gameId}`,{
    //   data: {winner: true}
    // })
    // base.update(`game/${this.props.match.params.gameId}/winningPositions`,{
    //   data: {'0':winningPositions[0], '1':winningPositions[1], '2':winningPositions[2]}
    // })
  }


  refreshBoard = async() => {
    console.log('refreshBoard!!!');
    // create new conversation
    let newConversation = await this.app.newConversation({display_name:`conversation-${Date.now()}`});

    // send custom event to old conversation
    this.conversation.sendCustomEvent({ type: 'new_game', body: {conversationId: newConversation.id}})
    .then((custom_event) => {
      console.log('new_game custom event: ',custom_event);

    }).catch(function (error) {
        console.log('refreshBoard error: ',error)
    });  


    // base.update(`game/${this.props.match.params.gameId}`,{
    //   data: {refresh: true}
    // }).then(()=>{
    //   //if (this.state.game.refresh){
    //     console.log('refreshBoard')
    //     //this.setState({myMoves:[]})
    //   //}
    //   base.update(`game/${this.props.match.params.gameId}`,{
    //     data: {winner: false, refresh:false}
    //   })
    //   base.update(`game/${this.props.match.params.gameId}/winningPositions`,{
    //     data: {'0':'', '1':'', '2':''}
    //   })
    //   base.update(`game/${this.props.match.params.gameId}/moves`,{
    //     data: {'0':'','1':'','2':'','3':'','4':'','5':'','6':'','7':'','8':''}
    //   })
    // })
  }

  copyURL = () => {
    document.getElementById("shareURL").select();
    try{
      document.execCommand('copy');
      document.getElementById("copyBtn").textContent = 'copied!';
      setTimeout(()=>{
        document.getElementById("copyBtn").textContent = 'copy'
      },1200)
    } catch(e){
      console.log('error: ',e)
    }


  }

  sendSMS = async() => {
    console.log('this.phoneNumber: ', this.phoneNumber.value);
    try {
      const response = await fetch('https://special-quilt-sign.glitch.me/sendSMS',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: `Hi. ${this.state.playerName} is inviting you to play Tic Tac Toe w/ video chat. Join them at ${this.shortURL}`,
          phoneNumber: this.phoneNumber.value
        })
      });
      const results = await response.json();
      if (response.status !== 200 ){
        throw new Error(results.error);
      }
    }catch(error){
      console.log('getPlayerCount error: ', error.message);
      this.setState({showError:true, errorText:error.message});
    }

  

  }


  displayShareUrl = () => {
    return (
      <div className={this.state.showShare ? 'fadeIn':'fadeOut'}>
        Here's the link so your friend can join.<br/>
        {/* <input type="text" id="shareURL" value={this.props.game.url} readOnly></input><button id="copyBtn" onClick={this.copyURL.bind(this)}>copy</button> */}
        <div id="share-container">
          <textarea type="text" id="shareURL" value={this.shortURL} readOnly></textarea><button id="copyBtn" onClick={this.copyURL.bind(this)}>copy</button>
        </div>
        <br/><br/>or send an SMS message
        <br/>+ <input type="number" name="phone-number" placeholder="i.e 14155550100" id="phone-number" size="30" ref={(input) => { this.phoneNumber = input; }}></input><button id="smsBtn" onClick={this.sendSMS.bind(this)}>send</button>
        <br/><br/><Link to={this.gameId}>Go to Game</Link>
      </div>
    )
  }

  displayWaiting = () => {
    return (
      <div className={this.state.showWait ? 'fadeIn':'fadeOut'}>
        Waiting for your friend to join.<br/>
      </div>
    )
  }



  render(){
    console.log(this.state);
    // console.log('signedIn: ',signedIn);
    return(
      <div className="Game">
         { this.state.showVideoChat ? <VideoChat apiKey={this.state.videoAPIKey} sessionId={this.state.videoSessionId} token={this.state.videoToken} game={this.state.game} gameId={this.props.match.params.gameId} /> : null}
        <div id="gameDisplay">
          {/* { (!this.state.game.started && !signedIn && (this.state.game.xPlayer==="" && this.state.game.oPlayer==="")) ? <StartGame setPlayerFromGame={this.setPlayer} game={this.state.game}/> : null}
          { (this.state.game.pageLoad <= 1) ? <StartGame setPlayerFromGame={this.setPlayer} game={this.state.game}/> : null} */}
          {/* { (this.state.gameCreator && !this.state.game.started) ? <StartGame setPlayerFromGame={this.startGame} game={this.state.game} setAppAndCoversationFromGame={this.setAppAndCoversation} /> : null} */}
          { this.state.showStart ? <StartGame setPlayerFromGame={this.startGame} game={this.state.game} setAppAndCoversationFromGame={this.setAppAndCoversation} /> : null}
          { // eslint-disable-next-line
            this.state.showJoin ? <JoinGame  setPlayerFromGame={this.joinGame} gameId={this.props.match.params.gameId} game={this.state.game} /> : null}
            {/* (!this.state.game.started && !this.state.signedIn && ((this.state.game.xPlayer!=="" && this.state.game.oPlayer==="")||(this.state.game.xPlayer==="" && this.state.game.oPlayer!==""))) ? <JoinGame  setPlayerFromGame={this.setPlayer} game={this.state.game} /> : null} */}
          {/* { (this.state.game.started && this.state.signedIn && (this.state.game.xPlayer!=="" && this.state.game.oPlayer!=="")) ? <PlayGame recordMoveFromGame={this.recordMove} recordWinFromGame={this.recordWin} refreshBoardFromGame={this.refreshBoard} {...this.state}/> : null} */}
          { this.state.showPlay ? <PlayGame recordMoveFromGame={this.recordMove} recordWinFromGame={this.recordWin} refreshBoardFromGame={this.refreshBoard} {...this.state}/> : null}
          {/* { (this.state.game.started && !this.state.signedIn && (this.state.game.xPlayer!=="" && this.state.game.oPlayer!=="")) ? <RejoinGame verifyPlayerFromGame={this.verifyPlayer} game={this.state.game}/> : null} */}
          { this.state.showRejoin ? <RejoinGame setPlayerFromGame={this.rejoinGame} verifyPlayerFromGame={this.verifyPlayer} gameId={this.props.match.params.gameId} game={this.state.game}/> : null}
          {this.state.showShare ? this.displayShareUrl() : null}
          {this.state.showWait ? this.displayWaiting() : null}
          {this.state.showError ? <div className="error">{this.state.errorText} Please try again.</div> : null}
        </div>
      </div>
    )
  }
}
export default Game
