import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import NexmoClient from 'nexmo-client';
import './StartGame.css'
// import base from '../rebase';

class StartGame extends Component {
  constructor (props){
    super(props);
    this.state = {
      playerName:'',
      playerMark:'',
      displayEnterName: true,
      displayXorO: false,
      displayShareUrl: false,
      gameId:''
    }
    let app;
    let conversation;
    let member;
    let gameId;
    let shortURL;
  }

  componentDidMount = () =>{
    let form = document.getElementById('creatorNameForm');
    form.addEventListener('submit', this.creatorNameSubmit);
  }

  creatorNameSubmit = (e) => {
    e.preventDefault();
    this.playerNameEntered.bind(this);
  }

  shortenURL = () => {
    this.gameId = this.conversation.id.replace('CON-','');
    console.log('gameId: ', this.gameId);
    // fetch(`https://api-ssl.bitly.com/v3/shorten?access_token=f98a5451d060cb4b77ed81efcd3d430992f25a7e&longUrl=https%3A%2F%2Fticreacttoev2.firebaseapp.com%2Fgame%2F${this.gameId}`)
    // .then(response => {
    //   return response.json()
    // }).then(data => {
    //   console.log(data.data.url)
    //   this.shortURL = data.data.url;
    //   // let pageLoadPlusOne = this.state.game.pageLoad + 1;
    //   // base.update(`game/${this.props.match.params.gameId}`,{
    //   //   data: {url:data.data.url, pageLoad: pageLoadPlusOne}
    //   // })
    //   // if (this.state.game.xPlayer==="" && this.state.game.oPlayer===""){
    //   //   this.setState({gameCreator: true})
    //   // }
    // }).catch(function (error) {
    //   console.log(error)
    // });

  }

  playerNameEntered = async() =>{
    // eslint-disable-next-line
    if (this.playerName.value !== '') {
      console.log('player Name: ', this.playerName.value);
      // try {
      //   const response = await fetch('https://special-quilt-sign.glitch.me/join',{
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //       username: this.playerName.value.toLowerCase(),
      //       display_name: this.playerName.value
      //     })
      //   });
      //   const results = await response.json();
      //   if (response.status !== 200 ){
      //     throw new Error(results.error);
      //   }
      //   console.log('results: ', results);
      //   this.app = await new NexmoClient({debug: false}).login(results.jwt);
      //   console.log('this.app: ', this.app);
      //   this.conversation = await this.app.newConversation({display_name:`conversation-${Date.now()}`});
      //   this.member = await this.conversation.join();
      //   console.log('this.conversation.id: ', this.conversation.id);
      //   // set up bitly url shortener
      //   this.shortenURL();
      //   console.log('this.member.id: ',this.member.id);
      //   //this.props.setAppAndCoversationFromGame(nexmoApp,nexmoConv);
      //   this.setState({
      //     playerName: this.playerName.value,
      //     displayEnterName: false,
      //     displayXorO: true,
      //   });

      //   this.conversation.on('player_joined', (sender, message) => {
      //     console.log('*** player joined: ', sender, message);
      //   });

      // }catch(err){
      //   console.log('playerNameEntered error: ', err.message);
      // }
      this.setState({
        playerName: this.playerName.value,
        displayEnterName: false,
        displayXorO: true,
      })
    } 
  }

  displayEnterName = () =>{
    return (
      <div className={this.state.displayEnterName ? 'fadeIn':'fadeOut'}>
        Hey there!<br/>
        What's your name?<br/>
        <form id="creatorNameForm">
          <input id="playerName" placeholder="Please enter here" type="text" ref={(input) => { this.playerName = input; }}></input><button type="button" onClick={this.playerNameEntered.bind(this)}>enter</button>
        </form>
      </div>
    )
  }

  setPlayerMark = (playerMark) => {
    // console.log(playerMark);
    // console.log('conversation to send custom event: ', this.conversation)
    // console.log(`create custom event to send playerName: ${this.state.playerName}, mark: ${playerMark} and memberId: ${this.member.id}`)
    this.setState({playerMark});
  }

  displayXorO = () => {
    return (
      <div className={this.state.displayXorO ? 'fadeIn':'fadeOut'}>
        Nice to meet you, {this.state.playerName}!<br/>
        Select <button onClick={this.setPlayerMark.bind(this,'X')} className={this.state.playerMark === 'X' ? 'baloo selected':'baloo'}>X</button> or <button onClick={this.setPlayerMark.bind(this,'O')} className={this.state.playerMark === 'O' ? 'baloo selected':'baloo'}>O</button> <button onClick={this.setPlayerToGame.bind(this)}>enter</button>
      </div>
    )
  }

  setPlayerToGame = () => {
    if (this.state.playerName !== '' && this.state.playerMark !== ''){
      this.props.setPlayerFromGame(this.state.playerName, this.state.playerMark,false);
      // this.conversation.sendCustomEvent({ type: 'player_joined', body: {memberId: this.member.id, name: this.state.playerName, mark:this.state.playerMark }})
      // .then((custom_event) => {
      //   console.log('custom event: ',custom_event);
      //   this.props.setPlayerFromGame(this.state.playerName, this.state.playerMark,false)
      //   // this.setState({
      //   //   displayXorO: false,
      //   //   displayShareUrl: true,
      //   // })
  
      // }).catch(function (error) {
      //     console.log('setPlayerToGame error: ',error)
      // });
    }

  }

  copyURL = () => {
    document.getElementById("shareURL").select();
    try{
      document.execCommand('copy');
      document.getElementById("copyBtn").textContent = 'copied!';
      setTimeout(()=>{
        document.getElementById("copyBtn").textContent = 'copy'
      },1200)
    } catch(e){
      console.log('error: ',e)
    }


  }

  // goToGamePage = () => {
  //   console.log('goToGamePage!');
  //   this.setState({gameId:this.gameId});
  // }

  displayShareUrl = () => {
    return (
      <div className={this.state.displayShareUrl ? 'fadeIn':'fadeOut'}>
        Here's the link so your friend can join.<br/>
        {/* <input type="text" id="shareURL" value={this.props.game.url} readOnly></input><button id="copyBtn" onClick={this.copyURL.bind(this)}>copy</button> */}
        <input type="text" id="shareURL" value={this.shortURL} readOnly></input><button id="copyBtn" onClick={this.copyURL.bind(this)}>copy</button>
        <br/><Link to={this.gameId}>Go to Game</Link>
      </div>
    )
  }

  render() {
    // console.log(this.state)
    // console.log(this.props)
    if (this.state.gameId){
      return (
        <Redirect to={`/game/${this.state.gameId}`}/>
      )
    }

    return (
      <div className="StartGame">
        {this.state.displayEnterName ? this.displayEnterName() : null}
        {this.state.displayXorO ? this.displayXorO() : null}
        {this.state.displayShareUrl ? this.displayShareUrl() : null}
      </div>
    )
  }
}

export default StartGame;
