import React, { Component } from 'react';
import './PlayGame.css';

// import base from '../rebase';
// let possible = [
//   ['0','1','2'],
//   ['3','4','5'],
//   ['6','7','8'],
//   ['0','3','6'],
//   ['1','4','7'],
//   ['2','5','8'],
//   ['0','4','8'],
//   ['2','4','6']
// ]

let possible = [
  [0,1,2],
  [3,4,5],
  [6,7,8],
  [0,3,6],
  [1,4,7],
  [2,5,8],
  [0,4,8],
  [2,4,6]
]


let myMoves=[];

class PlayGame extends Component {
  // constructor (props){
  //   super(props);
  // }

  componentDidMount=()=>{
    myMoves=this.props.myMoves;
    console.log('componentDidMount')
  }

  componentDidUpdate=()=>{
    // document.getElementById('0').style.color ="black";
    if (this.props.game.winner && this.props.game.winningPositions[0]!==''){
    // if (this.props.game.winningPositions.length!==0){
      document.getElementById(this.props.game.winningPositions[0]).style.color ="red";
      document.getElementById(this.props.game.winningPositions[1]).style.color ="red";
      document.getElementById(this.props.game.winningPositions[2]).style.color ="red";
    }
    if (this.props.game.refresh){
      console.log('componentDidUpdate in PlayGame.js');
      this.props.myMoves.splice(0,this.props.myMoves.length);
      document.getElementById('0').style.color ="black";
      // document.querySelectorAll(".space")[0].style.color = "black";
      console.log("winning positions: ",this.props.game.winningPositions)
      document.getElementById(this.props.game.winningPositions[0]).style.color ="black";
      document.getElementById(this.props.game.winningPositions[1]).style.color ="black";
      document.getElementById(this.props.game.winningPositions[2]).style.color ="black";
    }
  }

  didIWin = () => {
    // console.log('did I win?')
    for (let i=0; i<possible.length; i++){
      // console.log('myMoves: ',myMoves);
      // console.log('did I Win: ',i);
      // console.log('myMoves.indexOf: ',myMoves.indexOf(possible[i][0]));
      if ((myMoves.indexOf(possible[i][0]) !== -1) && (myMoves.indexOf(possible[i][1]) !== -1) && (myMoves.indexOf(possible[i][2]) !== -1)){
        console.log(this.props.playerMark,"you win!");
        this.props.recordWinFromGame(possible[i]);
        // base.update(`game/${this.props.match.params.gameId}`,{
        //   data: {winner:true}
        // })
        // base.update(`game/${this.props.match.params.gameId}/winningPositions`,{
        //   data: {'0':possible[i][0], '1':possible[i][1], '2':possible[i][2]}
        // })
      }
    }
  }

  recordMove = (move) =>{
    console.log('recordMove: ',move);
    console.log(`playerMark: ${this.props.playerMark} currentMove: ${this.props.game.currentMove} winner: ${this.props.game.winner}`)
    if (this.props.playerMark === this.props.game.currentMove && !this.props.game.winner){
      if (this.props.game.moves[move] === ""){
        this.props.myMoves.push(move);
        this.props.myMoves.sort();
        console.log('myMoves: ',this.props.myMoves)
        this.props.recordMoveFromGame(move);
        this.didIWin();
      } else {
        console.log('space is not empty')
      }
    } else {
      console.log('not your turn!')
    }
  }

  displayTurn = () => {
    return (
      <div>
        {this.props.game.currentMove === 'X' ? this.props.game.xPlayer : this.props.game.oPlayer}'s turn
      </div>
    )
  }

  displayWinner = () => {
    return (
      <div>
        {this.props.game.winner} wins!!
      </div>
    )
  }

  render() {
    console.log(this.props)
    // console.log('myMoves: ',myMoves)
    return (
      <div className="PlayGame">
        {/* <div>{this.props.game.currentMove === 'X' ? this.props.game.xPlayer : this.props.game.oPlayer}'s turn</div> */}
        {this.props.game.winner !== '' ? this.displayWinner() : this.displayTurn()}
        <div className="Board baloo">
          <button type="button" id="0" className="space" onClick={this.recordMove.bind(this,0)}>{this.props.game.moves[0]}</button>
          <button type="button" id="1" className="space" onClick={this.recordMove.bind(this,1)}>{this.props.game.moves[1]}</button>
          <button type="button" id="2" className="space" onClick={this.recordMove.bind(this,2)}>{this.props.game.moves[2]}</button>
          <button type="button" id="3" className="space" onClick={this.recordMove.bind(this,3)}>{this.props.game.moves[3]}</button>
          <button type="button" id="4" className="space" onClick={this.recordMove.bind(this,4)}>{this.props.game.moves[4]}</button>
          <button type="button" id="5" className="space" onClick={this.recordMove.bind(this,5)}>{this.props.game.moves[5]}</button>
          <button type="button" id="6" className="space" onClick={this.recordMove.bind(this,6)}>{this.props.game.moves[6]}</button>
          <button type="button" id="7" className="space" onClick={this.recordMove.bind(this,7)}>{this.props.game.moves[7]}</button>
          <button type="button" id="8" className="space" onClick={this.recordMove.bind(this,8)}>{this.props.game.moves[8]}</button>
        </div>
        <button onClick={this.props.refreshBoardFromGame.bind(this)}>New Game</button>
      </div>
    )
  }
}

export default PlayGame;
