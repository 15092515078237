import React, { Component } from 'react';
// import NexmoClient from 'nexmo-client';
import './RejoinGame.css'
// import base from '../rebase';


class RejoinGame extends Component {
  constructor(props){
    super(props);
    this.state = {
      showError:false
    }
    // let app;
    // let conversation;
    // let member;

  }

  componentDidMount = () => {
    let form = document.getElementById('rejoinPlayerNameForm');
    form.addEventListener('submit', this.rejoinPlayerNameSubmit);
  }

  rejoinPlayerNameSubmit = (e) => {
    e.preventDefault();
    this.rejoinPlayerNameEntered.bind(this);
  }

  // findPlayerMark = (move) => {
  //   return (
  //     move === 'X'
  //   )
  // }

  repopulateMoves = (playerMark) => {
    let myMoves =[];
    let gameMoves = this.props.game.moves;
    let playerMarkIndex = gameMoves.indexOf(playerMark);
    while (playerMarkIndex !== -1){
      myMoves.push(playerMarkIndex)
      playerMarkIndex = gameMoves.indexOf(playerMark, playerMarkIndex+1);
    }
    //console.log('myMoves: ',myMoves);
    return myMoves;
  }


  rejoinPlayerNameEntered = async() => {
    // console.log('rejoinPlayerNameEntered')
    this.setState({showError:false});
    if (this.rejoinPlayerName.value !== ''){
      // if (this.rejoinPlayerName.value === this.props.game.xPlayer){
      //   // console.log('moves: ',this.props.game.moves.find(this.findPlayerMark))
      //   //console.log('moves: ',this.props.game.moves.filter(move => move))
      //   //this.props.verifyPlayerFromGame(true,'X');
      //   // console.log(this.repopulateMoves('X'));
      //   this.props.verifyPlayerFromGame(true,'X',this.repopulateMoves('X'));
      // }else if (this.rejoinPlayerName.value === this.props.game.oPlayer){
      //   this.props.verifyPlayerFromGame(true,'O',this.repopulateMoves('O'));
      //   // console.log(this.repopulateMoves('O'));
      // } else {
      //   this.setState({showError:true})
      // }


      try {
        const response = await fetch('https://special-quilt-sign.glitch.me/verifyPlayer',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            playerName: this.rejoinPlayerName.value.toLowerCase(),
            conversationId: `CON-${this.props.gameId}`
          })
        });
        const results = await response.json();
        if (response.status !== 200 ){
          throw new Error(results.error);
        }
        console.log('results: ', results);
        this.props.setPlayerFromGame(this.rejoinPlayerName.value);
        // this.app = await new NexmoClient({debug: false}).login(results.jwt);
        // console.log('this.app: ', this.app);
        // this.conversation = await this.app.newConversation({display_name:`conversation-${Date.now()}`});
        // this.member = await this.conversation.join();
        // console.log('this.conversation.id: ', this.conversation.id);
        // // set up bitly url shortener
        // this.shortenURL();
        // console.log('this.member.id: ',this.member.id);
        // //this.props.setAppAndCoversationFromGame(nexmoApp,nexmoConv);
        // this.setState({
        //   playerName: this.playerName.value,
        //   displayEnterName: false,
        //   displayXorO: true,
        // });

        // this.conversation.on('player_joined', (sender, message) => {
        //   console.log('*** player joined: ', sender, message);
        // });

      }catch(err){
        console.log('verifyPlayer error: ', err.message);
        this.setState({showError:true});
      }


    }
  }

  showError = () => {
    return(
      <div className="error">{this.rejoinPlayerName.value} does not match our records.<br/>
        Please try again or <a href='https://trt.dwane.io'>start a new game</a>
      </div>
    )
  }
  render() {
    return (
      <div className="RejoinGame">
        Rejoin the Game?<br/>
        What's your name?
        <form id="rejoinPlayerNameForm">
          <input id="rejoinPlayerName" placeholder="Please enter here" type="text" ref={(input) => {this.rejoinPlayerName = input;}}></input><button onClick={this.rejoinPlayerNameEntered.bind(this)}>enter</button>
        </form>
        {this.state.showError ? this.showError(): null}
      </div>
    )
  }
}

export default RejoinGame;
