import React, { Component } from 'react';
// import base from '../rebase';
import { Redirect } from 'react-router-dom';
import './Home.css';
import logo from '../logo.svg';

class Home extends Component {
  constructor (props){
    super(props);
    this.state = {
      gameId:''
    }
  }
  setupAGame = () => {
    console.log('start a game!');
    this.setState({gameId: "create"});
    // base.push('game', {
    //   data: {
    //     '0' : '',
    //     '1' : '',
    //     '2' : '',
    //     '3' : '',
    //     '4' : '',
    //     '5' : '',
    //     '6' : '',
    //     '7' : '',
    //     '8' : '',
    //     oPlayer : '',
    //     xPlayer : '',
    //     url: '',
    //     started: false,
    //     winner: false,
    //     refresh: false,
    //     pageLoad: 0,
    //     currentMove: 'X',
    //     creatorName:'',
    //     moves: {'0':'','1':'','2':'','3':'','4':'','5':'','6':'','7':'','8':''},
    //     winningPositions: {'0':'','1':'','2':''}
    //   }
    // }).then(newLocation => {
    //   var generatedKey = newLocation.key;
    //   console.log(generatedKey);
    //   this.setState({gameId: generatedKey});
    //   // base.post(`connections/${generatedKey}`,{
    //   //   data:{sender:'', message: ''},
    //   //   then(err){
    //   //     if(!err){
    //   //       console.log(`Error initializing connection: ${err}`);
    //   //     }
    //   //   }
    //   // })
    // }).catch(err => {
    //   //handle error
    // });
  }

  render() {
    if (this.state.gameId){
      return (
        <Redirect to={`/game/${this.state.gameId}`}/>
      )
    }
    return (
      <div className="Home">
        <div className="Board">
          <div>T</div>
          <div>I</div>
          <div>C</div>
          <div></div>
          <div>
            <img src={logo} className="App-logo" alt="spinning React Logo" />
          </div>
          <div></div>
          <div>T</div>
          <div>O</div>
          <div>
            E
            <button tabIndex="0" className="round-star-label pulse" onClick={this.setupAGame.bind()}>
              <span className="label-text">
                Start!
              </span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
