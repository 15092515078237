import React, { Component } from 'react';
// import NexmoClient from 'nexmo-client';

// import base from '../rebase';

class JoinGame extends Component {
  // constructor (props){
  //   super(props);
  //   this.state = {
  //     secondPlayerName: ''
  //   }
  // }
  // constructor (props){
  //   super(props);
  //   let app;
  //   let conversation;
  //   let member;

  // }
  componentDidMount = () =>{
    console.log('this.props.gameId: ', this.props.gameId);
    let form = document.getElementById('secondPlayerNameForm');
    form.addEventListener('submit', this.secondPlayerNameSubmit);
  }

  secondPlayerNameSubmit = (e) => {
    e.preventDefault();
    this.secondPlayerNameEntered.bind(this);
  }

  secondPlayerNameEntered = async() => {
    if (this.secondPlayerName.value !== ''){
      // console.log('2nd player Name: ', this.secondPlayerName.value);


      // try {
      //   const response = await fetch('https://special-quilt-sign.glitch.me/join',{
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //       username: this.secondPlayerName.value.toLowerCase(),
      //       display_name: this.secondPlayerName.value
      //     })
      //   });
      //   const results = await response.json();
      //   if (response.status !== 200 ){
      //     throw new Error(results.error);
      //   }
      //   console.log('results: ', results);
      //   this.app = await new NexmoClient({debug: false}).login(results.jwt);
      //   console.log('this.app: ', this.app);
      //   this.conversation = await this.app.getConversation(`CON-${this.props.gameId}`);
      //   this.member = await this.conversation.join();
      //   console.log('this.conversation: ', this.conversation);
      //   console.log('this.member.id: ',this.member.id);
      //   let myMark;

        if (this.props.game.xPlayer !== ''){
          // myMark = 'O';
          this.props.setPlayerFromGame(this.secondPlayerName.value,'O',true);
        } else {
          // myMark = 'X';
          this.props.setPlayerFromGame(this.secondPlayerName.value,'X',true);
        }

      //   this.conversation.sendCustomEvent({ type: 'player_joined', body: {memberId: this.member.id, name: this.secondPlayerName.value, mark:myMark }})
      //   .then((custom_event) => {
      //     console.log('custom event: ',custom_event);
    
      //   }).catch(function (error) {
      //       console.log('setPlayerToGame error: ',error)
      //   });
  

  
      // }catch(err){
      //   console.log('playerNameEntered error: ', err.message);
      // }



    }
  }
  render() {
    // console.log(this.props)
    return (
      <div className="JoinGame">
        Hi! {this.props.game.creatorName} would like to play a game.
        What's your name?<br/>
        <form id="secondPlayerNameForm">
          <input id="secondPlayerName" placeholder="Please enter here" type="text" ref={(input) => { this.secondPlayerName = input; }}></input><button onClick={this.secondPlayerNameEntered.bind(this)}>enter</button>
        </form>
      </div>
    )
  }
}

export default JoinGame;
